<template>
    <div class="row">
        <div class="col-3" v-for="(configuration, configurationKey) in schema.properties" :key="configurationKey">
            <account-configuration-set
                :configuration="configuration"
                :configuration-key="configurationKey"
                @input="handleInput"
                :value="value"
            ></account-configuration-set>
        </div>
    </div>
</template>
<script>
import AccountConfigurationSet from './AccountConfigurationSet'

export default {
    name: 'AccountConfigurationParser',
    components: { AccountConfigurationSet },
    data () {
        return {
            hasError: false,
        }
    },
    props: {
        schema: {
            required: true,
        },
        value: {
            required: false,
            default () {
                return {}
            },
        },
    },
    methods: {
        handleInput ({ configurationKey, propertyKey, value, property }) {
            this.hasError = false

            if (this.isPropertyRequired(configurationKey) && (value === '')) {
                this.hasError = true
            }

            const currentValue = this.value
            if (!currentValue[configurationKey]) {
                this.$set(currentValue, configurationKey, {})
            }
            let newValue = value
            if (property.type === 'number' && isNaN(parseFloat(value)) === false) {
                newValue = parseFloat(value)
            }
            this.$set(currentValue[configurationKey], propertyKey, newValue)
            this.$emit('input', { value: currentValue, hasError: this.hasError })
        },
        isPropertyRequired (property) {
            if (this.schema.required.includes(property)) {
                return true
            }
            return false
        },
    },
}
</script>
<style scoped lang="scss">

</style>
