<template>
    <mercur-select v-on="$listeners" v-bind="$props">
        <template #label>{{ label }}</template>
        <option v-for="option in options" :key="option" :value="option">{{option}}</option>
        <template #note>
            <span class="form-error">{{errorMsg}}</span>
        </template>
    </mercur-select>
</template>
<script>
import AccountConfigurationFieldBase from './AccountConfigurationFieldBase'

export default {
    name: 'AccountConfigurationDropdown',
    mixins: [ AccountConfigurationFieldBase ],
    props: {
        options: {
            required: true,
            type: Array,
        },
    },
}
</script>
