export default {
    props: {
        property: {
            required: true,
        },
        label: {
            required: false,
            default: '',
        },
        defaultValue: {
            required: false,
        },
        value: {
            required: false,
        },
        required: {
            required: false,
            default: false,
        },
    },
    computed: {
        errorMsg () {
            if (this.required && this.value === '') {
                return `Property ${this.property.title} is required.`
            }
            return ''
        },
    },
    created () {
        this.$emit('input', this.value)
    },
}
