<template>
    <fieldset class="h-100 c-customer__account-configuration-entry">
        <legend><strong>{{configurationKey}}</strong></legend>
        <div class="text-mini mb-1" v-if="isOnlyOneFieldAllowed">
            Only one field is allowed to be filled in.
        </div>
        <div class="text-mini mb-1" v-if="isAnyFieldAllowed">
            Any field is allowed to be filled in.
        </div>
        <div v-for="(property, propertyKey) in configuration.properties" :key="propertyKey">
            <component
                v-if="getAccountConfigurationInputField(property)"
                :property="property"
                @input="handleInput({ configurationKey, propertyKey, value: $event, property })"
                :is="getAccountConfigurationInputField(property)"
                :required="isPropertyRequired(propertyKey)"
                v-bind="getAccountConfigurationProps({ configurationKey, propertyKey, property })"
            ></component>
            <b v-else>
                Type {{property.type}} is not mapped yet
            </b>
        </div>
    </fieldset>
</template>
<script>
import AccountConfigurationInput from './AccountConfigurationFields/AccountConfigurationInput'
import AccountConfigurationDropdown from './AccountConfigurationFields/AccountConfigurationDropdown'
import AccountConfigurationMulti from './AccountConfigurationFields/AccountConfigurationMulti'

export default {
    name: 'AccountConfigurationSet',
    components: { AccountConfigurationDropdown, AccountConfigurationInput, AccountConfigurationMulti },
    data () {
        return {
            hasError: false,
            disabledFields: [],
        }
    },
    props: {
        configuration: {

        },
        configurationKey: {

        },
        value: {
            required: false,
            default () {
                return {}
            },
        },
    },
    computed: {
        isOnlyOneFieldAllowed () {
            return this.configuration.oneOf
        },
        isAnyFieldAllowed () {
            return this.configuration.anyOf
        },
    },
    methods: {
        handleInput ($event) {
            const { configurationKey, propertyKey, value } = $event

            this.$emit('input', $event)
            if (!this.isOnlyOneFieldAllowed || value === null) {
                return
            }

            Object.entries(this.configuration.properties).forEach(([otherPropertyKey, otherProperty]) => {
                if (otherPropertyKey === propertyKey) {
                    return
                }
                this.$emit('input', {
                    configurationKey,
                    propertyKey: otherPropertyKey,
                    value: null,
                    property: otherProperty,
                })
            })
        },
        getDefaultValueForProperty ({ property, propertyKey }) {
            if (property.default !== undefined) {
                if (this.isOnlyOneFieldAllowed && Object.keys(this.configuration.properties).indexOf(propertyKey) !== 0) {
                    return null
                }
                if (property.type === 'array' && Array.isArray(property.default) === false) {
                    return [property.default]
                }
                return property.default
            }
            if (property.type === 'array') {
                return []
            }
            return null
        },
        getAccountConfigurationProps ({ configurationKey, propertyKey, property }) {
            const defaultValue = this.getDefaultValueForProperty({ property, propertyKey })
            let value = this.getDefaultValueForProperty({ property, propertyKey })
            if (this.value && this.value[configurationKey] && this.value[configurationKey][propertyKey] !== undefined) {
                value = this.value[configurationKey][propertyKey]
            }

            const props = {
                value,
                defaultValue,
                label: property.title || propertyKey,
            }
            if (property.enum) {
                props.options = property.enum
            }
            if (property.type === 'boolean') {
                props.options = [true, false]
            }
            if (property.type === 'array') {
                props.multiple = true
            }
            if (property.items && property.items.enum) {
                props.options = property.items.enum
            }
            return props
        },
        getAccountConfigurationInputField (property) {
            const type = property.type

            const enumValues = property.enum
            if (type === 'array') {
                return AccountConfigurationMulti
            }
            if (enumValues || type === 'boolean') {
                return AccountConfigurationDropdown
            }
            if (['string', 'integer', 'number', 'float', 'money', 'date'].includes(type)) {
                return AccountConfigurationInput
            }
            return null
        },
        isPropertyRequired (propertyKey) {
            return this.configuration.required && this.configuration.required.includes(propertyKey)
        },
    },
}
</script>
<style scoped lang="scss">

</style>
