<template>
    <div>
        <mercur-checkbox v-model="value" :disabled="params.isDisabled()"></mercur-checkbox>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name: 'CheckboxCell',
    computed: {
        value: {
            get () {
                return this.params.getValue()
            },
            set (value) {
                this.params.setValue(value)
            },
        },
    },
})
</script>
