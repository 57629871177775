<template>
    <div class="pb-5">
        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Add new customer</h1>
                </div>
            </div>
        </title-box>

        <form @submit.prevent="submitForm" class="mt-n4">
            <div class="container-fluid">
                <div class="row" v-if="hasPermission('createAccount') && selectedShop.applicationId !== '0'">
                    <div class="col-8">
                        <customer-personal-information :form="form" :$v="$v"></customer-personal-information>
                        <mercur-card>
                            <h2 class="mt-1 font-weight-normal">Account Configurations</h2>
                            <account-configuration-parser
                                v-if="accountConfigurationSchemas"
                                :schema="accountConfigurationSchemas"
                                @input="handleConfigurationInput"
                                :value="form.accountConfiguration"
                            >
                            </account-configuration-parser>
                        </mercur-card>
                        <div class="text-right mt-4">
                            <mercur-button class="btn" to="/customers">Cancel</mercur-button>
                            <mercur-button class="btn btn-primary" type="submit" :disabled="loading">Add customer</mercur-button>
                        </div>
                    </div>
                    <div class="col-4">
                        <address-form ref="addressForm" :options="{'title': 'Default Billing Address'}"></address-form>
                    </div>
                </div>
                <template v-else-if="this.selectedShop.applicationId === '0'">
                    <mercur-card>
                        <p>Select a shop to add customer</p>
                    </mercur-card>
                </template>
                <p v-else>
                    Not allowed to see this view
                </p>
            </div>
        </form>
    </div>
</template>

<script>
import CONFIG from '@root/config'

import CustomerPersonalInformation from '@/components/elements/customers/PersonalInformation'
import CheckboxCell from '@/components/elements/table/CheckboxCell'
import AddressForm from '@/components/elements/AddressForm'

import FormMixin from '@/mixins/Form'
import { required } from 'vuelidate/lib/validators'
import AccountConfigurationParser from '../../components/elements/customers/AccountConfigurationParser'

export default {
    name: 'AddCustomer',
    mixins: [FormMixin],
    components: { AccountConfigurationParser, CustomerPersonalInformation, AddressForm },
    props: {
        isComponent: {
            default: false,
        },
    },
    data () {
        return {
            form: {
                accountSettings: {
                    customerGroups: [],
                },
                roles: [],
                language: 'en-GB',
            },
            url: CONFIG.API.ROUTES.CUSTOMERS.GROUPS.OVERVIEW,
            options: {
                columns: {
                    '': {
                        field: 'selected',
                        width: 35,
                        maxWidth: 35,
                        cellRendererFramework: CheckboxCell,
                        suppressMenu: true,
                        cellRendererParams: (params) => {
                            return {
                                disabled: this.loading,
                                getValue: () => {
                                    if (this.form.accountSettings.customerGroups.find((item) => item.groupId === params.data.groupId)) {
                                        return true
                                    }
                                    return false
                                },
                                setValue: (value) => {
                                    if (value === true) {
                                        this.form.accountSettings.customerGroups.push({
                                            groupId: params.data.groupId,
                                        })
                                    } else {
                                        const index = this.form.accountSettings.customerGroups.findIndex((item) => item.groupId === params.data.groupId)
                                        this.form.accountSettings.customerGroups.splice(index, 1)
                                    }
                                },
                                isDisabled: () => {
                                    return false
                                },
                            }
                        },
                    },
                    'Customer Group Name': { field: 'groupName' },
                },
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'set as default group',
                            onClick: ({ data }) => {
                                if (!this.form.accountSettings.customerGroups.map((e) => e.groupId).includes(data.groupId)) {
                                    this.form.accountSettings.customerGroups.push({
                                        groupId: data.groupId,
                                    })
                                }
                                this.$set(this.form.accountSettings, 'defaultCustomerGroup', data.groupName)
                            },
                        },
                    ],
                },
                paginationPageSize: 10,
                cacheBlockSize: 10,
            },
            accountConfigurationSchemas: {},
            isLoadingConfigurations: true,
            hasConfigurationError: false,
        }
    },
    validations: {
        form: {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            email: {
                required,
            },
            gender: {
                required,
            },
            language: {
                required,
            },
            countryCode: {
                required,
            },
        },
    },
    created () {
        this.getConfigurationSchemas()
    },
    computed: {
        action () {
            return CONFIG.API.ROUTES.CUSTOMERS.ADD
        },
    },
    watch: {
        selectedShop: {
            handler () {
                if (this.selectedShop.applicationId === '0') {
                    return
                }
                this.getConfigurationSchemas()
            },
            immediate: true,
        },
    },
    methods: {
        getConfigurationSchemas () {
            const payload = {
                applicationId: this.selectedShop.applicationId,
            }
            this.post(CONFIG.API.ROUTES.CUSTOMERS.CONFIGURATIONS.CONFIGURATION_SCHEMAS, payload)
                .then(({ data }) => {
                    this.$set(this, 'accountConfigurationSchemas', data)

                    this.isLoadingConfigurations = false
                })
        },
        handleConfigurationInput (data) {
            this.form.accountConfiguration = data.value
            this.hasConfigurationError = data.hasError
        },
        submitForm () {
            const addressForm = this.$refs.addressForm
            this.$v.$touch()
            if (addressForm.$v) {
                addressForm.$v.$touch()
            }

            if ((addressForm.$v && addressForm.$v.$invalid) || this.hasConfigurationError) {
                return
            }

            this.form.defaultBillingAddress = addressForm.form.address

            if (addressForm.form.additionalInfo && addressForm.form.additionalInfo.eInvoice && Object.keys(addressForm.form.additionalInfo.eInvoice).length) {
                this.form.additionalInfo = addressForm.form.additionalInfo
            }

            this.submit(this.action, this.form).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Customer was added',
                })

                if (this.isComponent) {
                    this.$emit('added', data)
                    return
                }
                setTimeout(() => {
                    this.$router.push({
                        name: 'CustomersWrapper',
                    })
                }, 1000)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .groups-table {
        border-top: 1px solid #d6d6d6;
    }
</style>
