<template>
    <mercur-input v-on="$listeners" v-bind="$props">
        {{ label }}
        <template #note>
            <span class="form-error">{{errorMsg}}</span>
        </template>
    </mercur-input>
</template>
<script>
import AccountConfigurationFieldBase from './AccountConfigurationFieldBase'
export default {
    name: 'AccountConfigurationInput',
    mixins: [ AccountConfigurationFieldBase ],
    props: {
        property: {
            required: true,
        },
    },
}
</script>
