<template>
    <mercur-card data-e2e="customerAddressAddForm">
        <h2 class="mt-1 font-weight-normal">{{ options.title }}</h2>
        <form @submit.prevent="submitForm">
            <div class="row">
                <div class="col-12">
                    <div class="mb-3" :class="getValidationClass($v, ['address', 'addressType'])">
                        <p class="mb-1">Address type</p>
                        <span class="ml-n1 mr-2">
                            <input type="radio" id="BUSINESS" value="BUSINESS" v-model="form.address.addressType">
                            <label for="BUSINESS">BUSINESS</label>
                        </span>
                        <input data-e2e="costumerAddressResidentialType" type="radio" id="RESIDENTIAL" value="RESIDENTIAL" v-model="form.address.addressType">
                        <label for="RESIDENTIAL">RESIDENTIAL</label>
                        <span class="form-error" v-if="!$v.form.address.addressType.required">Address type is required</span>
                    </div>
                </div>
            </div>
            <!-- ONLY FOR BUSINESS TYPE ADDRESS -->
            <template v-if="form.address.addressType === 'BUSINESS'">
                <div class="row">
                    <div class="col-sm-6">
                        <mercur-input v-model="form.address.companyName">Company name</mercur-input>
                    </div>
                    <div class="col-sm-6">
                        <mercur-input v-model="form.address.vatNumber">Vat number</mercur-input>
                    </div>
                </div>
            </template>
            <div class="row">
                <div class="col-6">
                    <mercur-input data-e2e="costumerAddressFirstNameInput" v-model="form.address.firstName"  :class="getValidationClass($v, ['address', 'firstName'])">
                        First name
                        <template #note><span class="form-error" v-if="!$v.form.address.firstName.required">First name is required</span></template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input data-e2e="costumerAddressLastNameInput" v-model="form.address.lastName"  :class="getValidationClass($v, ['address', 'lastName'])">
                        Last name
                        <template #note><span class="form-error" v-if="!$v.form.address.lastName.required">Last name is required</span></template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input data-e2e="costumerAddressAddressInput" v-model="form.address.address"  :class="getValidationClass($v, ['address', 'address'])">
                        Address
                        <template #note><span class="form-error" v-if="!$v.form.address.address.required">Address is required</span></template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input data-e2e="costumerAddressAdditionalAddressInput" v-model="form.address.addressAdditional">
                        Address additional
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input data-e2e="costumerAddressHouseNumberAddressInput" v-model="form.address.houseNumber">
                        House number
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input data-e2e="costumerAddressHouseAdditionalNumberAddressInput" v-model="form.address.houseNumberAdditional">
                        House number additional
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input data-e2e="costumerAddressZipCodeAddressInput" v-model="form.address.zipCode" :class="getValidationClass($v, ['address', 'zipCode'])">
                        Zip Code
                        <template #note><span class="form-error" v-if="!$v.form.address.zipCode.required">Zip code is required</span></template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input data-e2e="costumerAddressCityAddressInput" v-model="form.address.city" :class="getValidationClass($v, ['address', 'city'])">
                        City
                        <template #note><span class="form-error" v-if="!$v.form.address.city.required">City is required</span></template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <country-selector data-e2e="costumerAddressCountrySelector" class="mb-3" v-if="Object.keys(form.address).length || !options.formData" v-model="form.address.country" :$v="$v.form.address.country" reducer="countryName" />
                </div>
                <div class="col-6">
                    <mercur-input data-e2e="costumerAddressPhoneNumberInput" v-model="form.address.phone" :class="getValidationClass($v, ['address', 'phone'])">
                        Phone number
                        <template #note><span class="form-error" v-if="!$v.form.address.phone.required">Phone number is required</span></template>
                    </mercur-input>
                </div>
            </div>
            <template v-if="isItaly">
                <div class="row">
                    <div class="col-6">
                        <mercur-input v-model="form.address.additionalInfo.eInvoice.uniqueCode" :class="getValidationClass($v, ['address', 'additionalInfo', 'eInvoice', 'uniqueCode'])">
                            Unique Code
                            <template #note>
                                <span class="form-error" v-if="!$v.form.address.additionalInfo.eInvoice.uniqueCode.required">Unique Code is required</span>
                                <span class="form-error" v-if="!$v.form.address.additionalInfo.eInvoice.uniqueCode.minLength">Unique Code must have at least 7 digits</span>
                            </template>
                        </mercur-input>
                    </div>
                    <div class="col-6">
                        <mercur-input v-model="form.address.additionalInfo.eInvoice.pecEmail" :class="getValidationClass($v, ['address', 'additionalInfo', 'eInvoice', 'pecEmail'])">
                            Pec Email
                            <template #note>
                                <span class="form-error" v-if="!$v.form.address.additionalInfo.eInvoice.pecEmail.required">Pec email is required</span>
                            </template>
                        </mercur-input>
                    </div>
                    <div class="col-6">
                        <mercur-input v-model="form.address.additionalInfo.eInvoice.fiscalCode" :class="getValidationClass($v, ['address', 'additionalInfo', 'eInvoice', 'fiscalCode'])">
                            Fiscal code
                            <template #note>
                                <span class="form-error" v-if="!$v.form.address.additionalInfo.eInvoice.fiscalCode.required">Fiscal code is required</span>
                                <span class="form-error" v-if="!$v.form.address.additionalInfo.eInvoice.fiscalCode.minLength">Fiscal code needs to be between 11 and 16 digits</span>
                            </template>
                        </mercur-input>
                    </div>
                </div>
            </template>
            <div v-if="options.action" class="text-right">
                <mercur-button data-e2e="costumerAddressSaveButton" type="submit" class="btn btn-primary" :disabled="loading">Save</mercur-button>
            </div>
        </form>
    </mercur-card>
</template>

<script>
import FormMixin from '@/mixins/Form'
import { required, requiredIf, minLength, maxLength } from 'vuelidate/lib/validators'
import CountrySelector from '@/components/elements/CountrySelector'

export default {
    name: 'AddressForm',
    mixins: [FormMixin],
    components: { CountrySelector },
    props: {
        options: {
            default () {
                return {}
            },
        },
    },
    data () {
        return {
            form: {
                address: {
                    additionalInfo: {
                        eInvoice: {},
                    },
                },
            },
        }
    },
    watch: {
        isItaly (value) {
            if (value && !this.form.address.additionalInfo) {
                this.form.address.additionalInfo = {
                    eInvoice: {},
                }
            }
        },
    },
    computed: {
        isItaly () {
            if (!this.form.address.country || !this.form.address.addressType) {
                return false
            }

            return this.form.address.country === 'Italy' && this.form.address.addressType === 'BUSINESS'
        },
    },
    methods: {
        submitForm () {
            const payload = JSON.parse(JSON.stringify(this.form.address))
            if (payload.addressType === 'RESIDENTIAL') {
                payload.vatNumber = null
                payload.companyName = null
            }
            this.submit(this.options.action, payload, this.options.notification).then(() => {
                this.$emit('success')
            })
        },
    },
    beforeMount () {
        if (this.options.formData) {
            this.form.address = { ...this.form.address, ...this.options.formData }
        }
    },
    validations: {
        form: {
            address: {
                addressType: {
                    required,
                },
                firstName: {
                    required,
                },
                lastName: {
                    required,
                },
                address: {
                    required,
                },
                zipCode: {
                    required,
                },
                city: {
                    required,
                },
                phone: {
                    required,
                },
                country: {
                    required,
                },
                additionalInfo: {
                    eInvoice: {
                        uniqueCode: {
                            required: requiredIf(function () {
                                return this.isItaly
                            }),
                            minLength: minLength(7),
                        },
                        pecEmail: {
                            required: requiredIf(function () {
                                return this.isItaly
                            }),
                        },
                        fiscalCode: {
                            required: requiredIf(function () {
                                return this.isItaly
                            }),
                            minLength: minLength(11),
                            maxLength: maxLength(16),
                        },
                    },
                },
            },
        },
    },
}
</script>
