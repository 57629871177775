<template>
    <div>
        <template v-if="options">
            <pretty-select
                clas="mb-3"
                :placeholder="label"
                :options="prettySelectOptions"
                v-on="$listeners"
                v-bind="$props"
            ></pretty-select>
        </template>
        <span class="pretty-select-error">{{errorMsg}}</span>
    </div>
</template>
<script>
import AccountConfigurationFieldBase from './AccountConfigurationFieldBase'
import PrettySelect from '../../../utils/PrettySelect'

export default {
    name: 'AccountConfigurationMulti',
    components: { PrettySelect },
    mixins: [ AccountConfigurationFieldBase ],
    props: {
        property: {
            required: true,
        },
        options: {
            required: false,
        },
        multiple: { },
    },
    computed: {
        prettySelectOptions () {
            const prettySelectOptions = {}
            if (this.options && this.options.length) {
                prettySelectOptions.options = this.options
            } else {
                prettySelectOptions.taggable = true
            }

            prettySelectOptions.multiple = this.multiple

            return prettySelectOptions.options
        },
        errorMsg () {
            if (this.required && (!this.value || this.value.length === 0)) {
                return `Property ${this.property.title} is required.`
            }
            return ''
        },
    },
}
</script>
